<template>
    <div class="shop_body">
        <div class="shop_box">
            <div class="goods-list" v-loading="loading">
                <div class="shopitem_top" v-for="(item3, ix) in streetStore" :key="item3.store_id">
                    <div class="shopitem_top_item">
                        <div class="item-l">
                            <div class="avatar-wrap">
                                <img class="avatar" :src="$img(item3.store_image || defaultShopImage)" @error="item.logo = defaultShopImage" />
                            </div>
                        </div>
                        <div class="item-r">
                            <div class="item_r_top">
                                <div class="item-info title">{{ item3.store_name }}</div>
                                <div class="item-info address">
                                    <img src="@/assets/images/store/address.png" />
                                    <span class="label">地址：</span>
                                    <span class="info-detail">{{ item3.full_address }}{{ item3.address }}</span>
                                </div>
                                <div class="item-info address">
                                    <img src="@/assets/images/store/time.png" />
                                    <span class="label">营业时间：</span>
                                    <span class="info-detail">{{ item3.open_date }}</span>
                                </div>
                            </div>
                            <div class="item_r_bottom">
                                <div class="bot_but" v-if="item3.is_pickup == 1">支持自提</div>
                                <div class="bot_but" v-if="item3.is_o2o == 1">支持配送</div>
                            </div>
                        </div>
                    </div>
                    <div class="shop_division" v-if="ix < streetStore.length - 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { storePage2_0 } from "@/api/street"
    export default {
        name: "shop_street",
        data: () => {
            return {
                site_id: 0,
                streetStore: [],
                loading: false
            }
        },
        created() {
            if (this.$route.query.site_id) {
                this.site_id = this.$route.query.site_id
                this.getStorePage2_0()
            } else {
                this.$message.error("缺少店铺id")
                this.$router.push("/index")
            }
        },
        computed: {
            ...mapGetters(["token", "defaultShopImage", "addonIsExit", "defaultGoodsImage"])
        },
        methods: {
            //获取店铺的门店信息
            getStorePage2_0() {
                storePage2_0({ site_id: this.site_id })
                    .then(res => {
                        if (res.code == 0) {
                            if (res.data.count > 0) {
                                this.streetStore = res.data.list
                            }
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shop_body {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .shop_box {
            width: calc(100% - 80px);
            margin: 32px 0 8px 0;
            background-color: #ffffff;
            box-sizing: border-box;
            .goods-list {
                margin: 2px 62px 40px 62px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .shopitem_top {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .shop_division {
                        height: 0;
                        width: 100%;
                        margin-top: 40px;
                        border-bottom: 1px solid #e3e3e3;
                    }

                    .shopitem_top_item {
                        box-sizing: border-box;
                        width: 100%;
                        margin: 40px 0 0 0;
                        display: flex;
                        align-items: left;

                        .item-l {
                            width: 288px;
                            height: 179px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            .avatar {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .item-r {
                            // width: calc(100% -288px);
                            width: 680px;
                            height: 179px;
                            margin-left: 42px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .item_r_top {
                                color: #727272;
                                display: flex;
                                flex-direction: column;
                                align-items: left;
                                justify-content: left;

                                position: relative;
                                .item-info {
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    line-height: 20px;
                                    img {
                                        width: 20px;
                                        margin-right: 12px;
                                    }
                                    .label {
                                    }
                                }
                                .title {
                                    font-size: 24px;
                                    line-height: 33px;
                                    font-family: PingFang SC;
                                    margin-bottom: 5px;
                                }
                                .address {
                                    margin-top: 13px;
                                }
                            }
                            .item_r_bottom {
                                display: flex;
                                .bot_but {
                                    width: 89px;
                                    height: 29px;
                                    font-size: 14px;
                                    line-height: 29px;
                                    border-radius: 50px;
                                    border: 1px solid #d81e01;
                                    color: #d81e01;
                                    text-align: center;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
